import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "deliveryType",
    "scheduledDate",
    "priceLabel",
    "priceInput",
    "vehicleType",
    "timeSlotInput",
    "subtotalLabel",
    "totalLabel",
    "vatLabel",
    "floors",
    "exchange",
    "itemPriceInput",
    "itemsSubtotal",
    "riderOption",
    "promoCode",
    "discountValue"
  ];

  connect() {
    // No need to fetch the delivery price if the delivery has already been created
    if (!document.getElementById("delivery_id")) {
      this.quote();
    }
    this.element.addEventListener('itemRemoved', () => this.quote());
    if (this.hasPromoCodeTarget) {
      this.promoCodeTarget.addEventListener('input', () => this.quote());
    }
  }

  async quote() {
    try {
      const query = this.buildQuery();
      const pricingData = await this.fetchDeliveryPrice(query);
      this.updatePricing(pricingData);
    } catch (error) {
      console.error("An error occurred while fetching the delivery price:", error);
    }
  }

  buildQuery() {
    const vehicleQuery = this.buildVehicleQuery();
    const deliveryQuery = this.buildDeliveryQuery();
    const extrasQuery = this.buildExtrasQuery();
    const itemsQuery = this.buildItemsQuery();
    const dateQuery = this.buildDateQuery();
    const idQuery = this.buildIdQuery();
    const riderQuery = this.buildRiderQuery();
    const promoCodeQuery = this.buildPromoCodeQuery();

    return [vehicleQuery, deliveryQuery, extrasQuery, itemsQuery, dateQuery, idQuery, riderQuery, promoCodeQuery].join("&");
  }

  buildVehicleQuery() {
    const vehicleTypeVal = this.vehicleTypeTargets.find((vehicleTypeOption) => vehicleTypeOption.checked).value;
    return `vehicle_type=${vehicleTypeVal}`;
  }

  buildDeliveryQuery() {
    const deliveryTypeVal = this.deliveryTypeTargets.find((deliveryTypeOption) => deliveryTypeOption.checked).value;
    return `delivery_type=${deliveryTypeVal}`;
  }

  buildExtrasQuery() {
    let queryParts = [];

    if (this.hasFloorsTarget) {
      const floorsCount = parseInt(this.floorsTarget.value, 10) || 0;
      queryParts.push(`floors=${floorsCount}`);
    }

    if (this.hasExchangeTarget) {
      const isExchange = this.exchangeTarget.checked === true;
      queryParts.push(`exchange=${isExchange}`);
    }

    return queryParts.join('&');
  }

  buildItemsQuery() {
    const itemsSubtotal = parseFloat(this.calculateItemsSubtotal());
    return `items_subtotal=${itemsSubtotal}`;
  }

  buildDateQuery() {
    const deliveryTypeVal = this.deliveryTypeTargets.find((deliveryTypeOption) => deliveryTypeOption.checked).value;
    if (deliveryTypeVal === "scheduled" && this.scheduledDateTarget.value !== null) {
      const scheduledDateVal = this.scheduledDateTarget.value;
      const timeSlotVal = this.timeSlotInputTargets.find((timeSlotInput) => timeSlotInput.checked)?.value;
      return `scheduled_date=${scheduledDateVal}T${timeSlotVal}`;
    }
    return '';
  }

  buildIdQuery() {
    const deliveryId = this.getDeliveryId();
    if (deliveryId) {
      return `delivery_id=${deliveryId}`;
    }
    return '';
  }

  buildRiderQuery() {
    const riderOptionVal = this.riderOptionTargets.find((riderOption) => riderOption.checked)?.value;
    if (riderOptionVal) {
      return `rider_id=${riderOptionVal}`;
    }
    return '';
  }

  buildPromoCodeQuery() {
    if (this.hasPromoCodeTarget) {
      return `promo_code=${this.promoCodeTarget.value}`;
    }
    return '';
  }

  async fetchDeliveryPrice(query) {
    try {
      const response = await fetch(`/delivery-prices?${query}`, {
        headers: { accept: "application/json" },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error("An error occurred while fetching the delivery price:", error);
      throw error;
    }
  }

  updatePricing(pricingData) {
    const { basePrice, itemsSubtotal, subtotal, total, discount } = pricingData;

    if (this.hasItemsSubtotalTarget) {
      this.itemsSubtotalTarget.innerHTML = `€${(itemsSubtotal / 100).toFixed(2)}`;
    }
    this.priceLabelTarget.innerHTML = `€${(basePrice / 100).toFixed(2)}`;
    this.subtotalLabelTarget.innerHTML = `€${(subtotal / 100).toFixed(2)}`;
    if (this.promoCodeTarget.value) {
      const discountRow = document.getElementById('discount-row-template');
      if (discount > 0) {
        discountRow.style.display = 'table-row';
        discountRow.querySelector('[data-discount-target="discountValue"]').innerHTML = `-€${(discount / 100).toFixed(2)}`;
      }
    }
    this.totalLabelTarget.innerHTML = `€${(total / 100).toFixed(2)}`;
  }

  calculateItemsSubtotal() {
    return this.itemPriceInputTargets.reduce((subtotal, item) => {
      return subtotal + (item.value * 100 || 0);
    }, 0);
  }

  getDeliveryId() {
    const deliveryIdEle = document.getElementById("delivery_id");
    if (deliveryIdEle && deliveryIdEle.dataset.value !== "") {
      return deliveryIdEle.dataset.value;
    }
    return "";
  }
}
